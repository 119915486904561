<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmUserFormData && Object.keys(vmUserFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationuser_name">
                    {{cvUserNameLabel}}
                  </label>
                  <input
                    disabled
                    v-model="vmUserFormData.user_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <template>
                  <div class="col-md-12 mb-3 mt-3 p-0 pl-2">
                    <div class="row">
                      <div class="col-9">
                        <label
                          for="validationuser_role">
                          {{cvUserRoleLabel}}
                        </label>
                        <select
                          v-model="vmUserFormData.user_role"
                          track-by="user_role_name"
                          label="user_role_name"
                          class="form-control">
                          <option v-for="item of cvUserRoleOptions" :key="item.id.user_role_id" :value="item.user_role_id">
                            {{item.user_role_name}}
                          </option>
                        </select>
                      </div>
                      <div class="col-12 pt-4">
                        <button type="button" class="mt-3 btn btn-primary mr-1" @click="userEdit">Edit</button>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="vmUserFormData.user_role">
                  <div class="col-md-12 mb-3 mt-3 p-0 pl-2">
                    <div class="row">
                      <div class="col-9">
                        <label>Associate Organisation</label>
                        <multiselect
                          v-model="selectedUniversity"
                          :options="cvUnivOptions"
                          placeholder="Select Organisation"
                          label="org_name"
                          @search-change="organisationList($event)"
                          track-by="org_id">
                        </multiselect>
                      </div>
                      <div class="col-3 pt-4">
                        <button type="button" class="mt-3 btn btn-primary" @click="userEdit">Add</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3 mt-3 p-0 pl-2">
                    <label>Organisations Associated with <span class="primary-color">{{ vmUserFormData.user_name }}</span></label>
                    <template v-for="(org, index) of vmUserFormData.roles">
                      <h5 class="mt-3" :key="index+'org'">
                        {{ index }}. {{ org.module_type == "SCH" ? "School" : org.module_type == "UNIV" ? "University" : "Organisation" }} - {{org.org_name}} <small>({{ org.status }})</small><br><br>
                        <button type="button" class="btn btn-sm btn-primary pointer mr-2" @click="gotoManageOrganisation('organisations', org.module_type, org.module_obj_id)">View</button>
                        <button type="button" class="btn btn-sm btn-primary pointer mr-2" @click="gotoManageOrganisation('organisation_edit', org.module_type, org.module_obj_id, 'manage-users')">Manage Users</button>
                        <button type="button" class="btn btn-sm btn-primary pointer mr-2" @click="gotoManageOrganisation('organisation_edit', org.module_type, org.module_obj_id, 'edit-roles')">Manage Roles</button>
                        <button type="button" class="btn btn-sm btn-primary pointer mr-2" @click="deleteRole(org.module_obj_id, index)">Remove</button>
                      </h5>
                    </template>
                  </div>
                </template>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect"
import { User } from "../../../FackApi/api/user"
import { UserRole } from "../../../FackApi/api/userRole"
import ApiResponse from "../../../Utils/apiResponse.js"
import { Organisations } from "../../../FackApi/api/organisation"

export default {
  name: "UserRoleEdit",
  components: {
    Multiselect
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propUserObj: {
      type: Object,
      default: function () {
        return {
          "user_name": "",
          "user_role": "",
          "org_name": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "user_edit_console",
      cvSelectBoxText: "Select ",
      cvCardTitle: "Manager User Role & Organisation",
      cvSubmitBtn: "Edit",
      cvUserNameLabel: "User Name",
      cvUserRoleLabel: "Change User Role",
      showToast: false,
      cvLoadingStatus: false,
      cvUserRoleOptions: [],
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      cvUnivOptions: [],
      vmUserFormData: {},
      indd: 0,
      currUserId: null,
      selectedUniversity: null
    }
  },
  async mounted () {
    await this.userRoleList()
    await this.userView()
    await this.organisationList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    change () {
      this.indd += 1
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmUserFormData) {
          if (!this.vmUserFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * userView
     */
    async userView () {
      try {
        if (this.propOpenedInModal) {
          this.currUserId = this.propUserObj.user_id
        }
        else {
          this.currUserId = this.$route.params.user_id
        }

        let userViewResp = await User.userViewWithRoles(this, { curr_user_id: this.currUserId })
        if (userViewResp && userViewResp.resp_status) {
          this.vmUserFormData = userViewResp.resp_data.data
        }

        if (this.vmUserFormData.user_role_name && this.cvUserRoleOptions.length) {
          let userIndex = this.cvUserRoleOptions.findIndex(user => user.user_role_name === this.vmUserFormData.user_role_name)
          if (userIndex >= 0) {
            this.vmUserFormData.user_role = this.cvUserRoleOptions[userIndex]
            this.vmUserFormData.user_role_name = this.vmUserFormData.user_role
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    },
    /**
     * deleteRole
     */
    async deleteRole (moduleObjId, index) {
      try {
        const userModuleDelete = await UserRole.udfInviteDelete(this, this.currUserId, moduleObjId)
        if (userModuleDelete.resp_status) {
          this.vmUserFormData.roles.splice(index, 1)
        }
      }
      catch (error) {
        console.log("Exception IN deleteRole", error)
      }
    },
    /**
     * goto Manage Organisation Actions
     * Ex: this.$router.push("/organisation_edit/UNIV/GIDE_ORG_1#manage-users")
     *
     * #manage-users
     * #edit-roles
     */
    async gotoManageOrganisation (pageName = "organisations", orgType = "ORG", orgId = "GIDE_ORG_1", action = null) {
      let url = `/${pageName}/${orgType}/${orgId}`
      url = action ? `${url}#${action}` : url

      window.open(url, "_blank")
    },
    /**
     * userEdit
     */
    async userEdit () {
      try {
        this.cvLoadingStatus = true

        let payload = {
          curr_user_id: this.vmUserFormData.user_id,
          user_role: this.vmUserFormData.user_role
        }

        if (this.selectedUniversity && this.selectedUniversity.org_id) {
          payload.org_id = this.selectedUniversity.org_id
          payload.org_type = this.selectedUniversity.type
        }

        let userEditResp = await User.userEditConsole(this, payload)
        ApiResponse.responseMessageDisplay(this, userEditResp)

        if (userEditResp && !userEditResp.resp_status) {
          return false
        }
      }
      catch (err) {
        console.error("Exception occurred at userEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * userRoleList
     */
    async userRoleList () {
      try {
        let userRoleListResp = await UserRole.userRoleListConsole(this)
        if (userRoleListResp && !userRoleListResp.resp_status) {
          return false
        }
        this.cvUserRoleOptions = userRoleListResp.resp_data.data
      }
      catch (err) {
        console.error("Exception occurred at userRoleListConsole() and Exception:", err.message)
      }
    },
    /**
     * organisationList
     */
    async organisationList (searchString = null) {
      let filter = {}

      if (searchString && searchString.trim().length >= 4) {
        filter.search = searchString.trim()
      }

      filter.fields = [ "org_name", "org_city", "org_country" ]
      filter.type = ["UNIV", "ORG", "SCH"]

      const list = await Organisations.organisationListSearch(this, filter)
      if (list.resp_status) {
        this.cvUnivOptions = list.resp_data.data
      }
    }
  }
}
</script>
